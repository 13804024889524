
export default {
	firestorePath: "staffSurveyOptIns",
	firestoreRefType: "collection",
	moduleName: "staffSurveyOptIns",
	statePropName: "data",
	namespaced: true,
	sync: {
		guard: ["created_by", "updated_by"],
		defaultValues: {
			status: "published",
			email: null, 
			preference: null
		},
	},
	state: {},
	getters: {
	},
	mutations: {},
	actions: {},
};
