// import mwsurveyutils from "@/assets/surveyUtilities.js";

export default {
	firestorePath: "staffSurveyQuestions",
	firestoreRefType: "collection",
	moduleName: "staffSurveyQuestions",
	statePropName: "data",
	namespaced: true,
	sync: {
		defaultValues: {
			approved: false, 
			text: null, 
			answerOptions: null, 
			tooltip: null,
			organisations: [], 
			jobFunctions: [],
			services: [], 
			section: null, 
			question: null
		},
	},
	state: {},
	getters: {
		available(state, _, rootState){
			let user = rootState.auth.data.id;
			return Object.values(state.data).filter( item => {
				return item.created_by == user || item.approved;
			})
		}
	},
	mutations: {},
	actions: {},
};
