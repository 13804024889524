// import mwsurveyutils from "@/assets/surveyUtilities.js";

export default {
	firestorePath: "staffSurveyTags",
	firestoreRefType: "collection",
	moduleName: "staffSurveyTags",
	statePropName: "data",
	namespaced: true,
	sync: {
		defaultValues: {
			organisation: null, 
			name: null, 
			description: null
		},
	},
	state: {},
	getters: {
		forOrganisation(state){
			return(id) => {
				let values = Object.values(state.data);
				return values.filter( v => v.organisation == id )
			}
		}
	},
	mutations: {},
	actions: {},
};
